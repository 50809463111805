import Vue from 'vue';
import VueRouter from 'vue-router';
import Notifications from 'vue-notification';

import App from './componenets/app.vue';
import Signin from './componenets/signin.vue';
import Websites from './componenets/websites.vue';
import Servers from './componenets/servers.vue';

const routes = [
    { path: '/', redirect: '/websites' },
    { path: '/signin', component: Signin, meta: { title: 'Sign in' } },
    { path: '/websites', component: Websites, meta: { title: 'Websites' } },
    { path: '/servers', component: Servers, meta: { title: 'Servers' } },
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    if (!token && to.path !== '/signin') {
        next('/signin');
        return;
    }

    document.title = `ServerChief - ${to.meta.title}`;
    next();
});

window.addEventListener('load', () => {
    Vue.use(VueRouter);
    Vue.use(Notifications);
    const RootAppContructor = Vue.extend(App);

    const RootApp = new RootAppContructor({
        router: router,
    }).$mount('#app');
});
