






































import axios from 'axios';

import config from '../config.json';

import Dash from './dash.vue';

export default {
    components: {
        Dash
    },

    data() {
        return { servers: [] };
    },

    methods: {
        fetchServers() {
            axios.get(config.server + '/servers', {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    this.servers = response.data;
                })
                .catch((error) => {
                    this.$notify({
                        title: 'Unable to get servers',
                        text: 'It was not possible to get the list of servers. Please try it again.'
                    });
                });
        }
    },

    mounted() {
        this.fetchServers()
    }
};
