


















import axios from 'axios';

import config from '../config.json';

export default {
    data() {
        return { email: "", password: "" };
    },

    methods: {
        onSubmit() {
            axios.post(config.server + '/auth', {
                email: this.email,
                password: this.password,
            })
                .then((response) => {
                    localStorage.setItem('token', response.data);
                    document.location.pathname = '/';
                })
                .catch((error) => {
                    this.$notify({
                        type: 'error',
                        title: 'Unable to login',
                        text: 'It was not possible to authenticate you. Please try it again.',
                        duration: 5000
                    });
                });
        }
    }
};
