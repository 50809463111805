







































































































































import axios from 'axios';

import config from '../config.json';

import Dash from './dash.vue';

export default {
    components: {
        Dash
    },

    data() {
        return {
            newWebsite: {
                domain: '',
                description: '',
                server: 'web1.dmedia.sk',
                password: '',
                frontController: true,
                subdirectoryRoot: '',
                enableFrames: false,
            },
            creating: false,
            websites: [],
            putRunning: false,
        }
    },

    methods: {
        fetchWebsites() {
            axios.get(config.server + '/websites', {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    this.websites = response.data.map((website) => ({
                        ...website,
                        editing: false,
                        password: '',
                    }));
                })
                .catch((error) => {
                    this.$notify({
                        type: 'error',
                        title: 'Unable to get websites',
                        text: 'It was not possible to get the list of websites. Please try it again.'
                    });
                });
        },

        createWebsite() {
            this.putRunning = true;

            axios.put(config.server + '/websites', {
                domain: this.newWebsite.domain,
                description: this.newWebsite.description,
                server: this.newWebsite.server,
                password: this.newWebsite.password,
                frontController: this.newWebsite.frontController,
                subdirectoryRoot: this.newWebsite.subdirectoryRoot,
                enableFrames: this.newWebsite.enableFrames,
            }, {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    this.$notify({
                        type: 'success',
                        title: 'Created website',
                        text: 'Website was created. Command output can be seen in the console.'
                    });

                    console.log(response.data);
                    this.fetchWebsites();
                    this.putRunning = false;
                    this.creating = false;
                    this.newWebsite = {
                        domain: '',
                        description: '',
                        server: 'web1.dmedia.sk',
                        password: '',
                        frontController: true,
                        subdirectoryRoot: '',
                        enableFrames: false,
                    };
                })
                .catch((error) => {
                    this.$notify({
                        type: 'error',
                        title: 'Unable to create website',
                        text: 'Website could not be created. Command output can be seen in the console.'
                    });

                    console.error(error);
                    this.putRunning = false;
                });
        },

        editWebsite(website) {
            this.putRunning = true;

            axios.put(config.server + '/websites/' + website.id, {
                domain: website.domain,
                description: website.description,
                server: website.server,
                password: website.password,
                frontController: website.frontController,
                subdirectoryRoot: website.subdirectoryRoot,
                enableFrames: website.enableFrames,
            }, {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                }
            })
                .then((response) => {
                    this.$notify({
                        type: 'success',
                        title: 'Website updated',
                        text: 'Website was updated. Command output can be seen in the console.'
                    });

                    console.log(response.data);
                    this.fetchWebsites();
                    this.putRunning = false;
                })
                .catch((error) => {
                    this.$notify({
                        type: 'error',
                        title: 'Unable to update website',
                        text: 'Website could not be updated. Command output can be seen in the console.'
                    });

                    console.error(error);
                    this.putRunning = false;
                });
        }
    },

    mounted() {
        this.fetchWebsites();
    }
}
